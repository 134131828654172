<template>
  <div class="layout-staff-side-menu" :class="[showSideMenu ? 'isOpen' : 'isClose']">
    <div class="side-menu-toggle-icon" @click.prevent="toggleSideMenu">
      <font-awesome-icon class="menu-user-icon" :icon="['fal', showSideMenu ? 'angle-left' : 'angle-right']" />
      <span></span>
    </div>
    <div class="side-menu-logo-section">
      <img src="/staff/Logo-Dark.svg" alt="Logo" class="staff-logo" />
    </div>
    <div class="menu-user-section">
      <div class="menu-user-labels cursor-pointer mr-3 ml-3" @click="toggleUserMenu">
        <div class="grid pl-2 pb-1 pt-3">
          <div class="col-2 p-0">
            <ImagePhoto
              class="user-avatar"
              :src="userAvatar"
              alt="User avatar"
              :fallback-image="userAvatarImage"
              height="2.5rem"
              width="2.5rem"
              shape="circle"
            />
          </div>
          <div class="col-7 p-0 pl-1">
            <p class="user-fullName capitalize">{{ userName }}</p>
            <p class="user-department capitalize">{{ userDepartment }} Department</p>
          </div>
          <div class="col-2 p-0">
            <font-awesome-icon class="menu-user-icon mt-3 text-lg" :icon="['fal', 'angle-right']" />
          </div>
        </div>
      </div>
      <div class="divider mb-4"></div>
      <OverlayPanel ref="userMenu" :breakpoints="{ '769px': '230px' }" class="custom-overlay width-230">
        <div class="userMenuSection">
          <p @click="staffProfile">
            <i class="pi pi-user"></i>
            <span>Profile</span>
          </p>
          <p @click.prevent="changePassword">
            <i class="pi pi-lock"></i>
            <span>Change Password</span>
          </p>
          <p @click.prevent="logoutUser">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
          </p>
        </div>
      </OverlayPanel>
    </div>
    <ScrollPanel aria-controls="left" class="items-holder">
      <div v-for="(item, index) in sideMenuItems" :key="index" class="menu-item">
        <div class="menu-item-name">
          <template v-if="item.route">
            <router-link
              class="menu-item-parent"
              :to="{ name: item.route }"
              :class="{
                'router-link-active router-link-exact-active menu-item-parent': isInAliases(item.aliases as string[])
              }"
              @click="closeOtherMenu(index)"
            >
              <span class="side-menu-icon p-1 parent-item-icon">
                <font-awesome-icon class="parent-item-icon" :icon="['far', item.icon]" />
              </span>

              {{ item.name }}
              <Tag
                v-if="item.count"
                severity="warning"
                :value="item.count"
                class="menu-item-count"
                :class="item.children.length ? 'mr-5' : 'mr-2'"
              />
            </router-link>
          </template>
          <template v-else>
            <p class="cursor-pointer menu-item-parent" @click="toggleMenuChildren(index)">
              <span class="side-menu-icon p-1 parent-item-icon">
                <font-awesome-icon :icon="['far', item.icon]" />
              </span>
              {{ item.name }}
            </p>
            <Tag
              v-if="item.count"
              severity="warning"
              :value="item.count"
              class="menu-item-count mr-2"
              :class="item.children ? 'mr-6' : ''"
            />
            <font-awesome-icon
              v-if="item.children && item.status"
              class="cursor-pointer text-gray-6 side-menu-icon-close mr-3"
              :icon="['fal', 'angle-up']"
              @click="toggleMenuChildren(index)"
            />
            <font-awesome-icon
              v-else-if="item.children && !item.status"
              class="cursor-pointer text-gray-6 side-menu-icon-open mr-3"
              :icon="['fal', 'angle-down']"
              @click="toggleMenuChildren(index)"
            />
          </template>
        </div>
        <div v-show="item.status && item.children.length" class="menu-item-children">
          <div v-for="(childItems, childIndex) in item.children" :key="childIndex">
            <template v-if="childItems.route">
              <router-link
                class="menu-item-child"
                :to="{
                  name: childItems.route.name || childItems.route,
                  query: childItems.route.query || {}
                }"
                :class="{ 'active-menu': isInAliases(childItems.aliases as string[]) }"
                @click="closeOtherMenu(index, childIndex)"
              >
                {{ childItems.name }}</router-link
              >
            </template>
            <template v-else>
              <p
                class="cursor-pointer menu-item-child"
                :class="childItems.status ? 'mb-1' : ''"
                @click="toggleMenuSubChildren(index, childIndex)"
              >
                {{ childItems.name }}
                <font-awesome-icon
                  v-if="childItems.children && childItems.status"
                  class="cursor-pointer text-gray-6 side-menu-icon-close sub-child-icon-color"
                  :icon="['fal', 'minus-square']"
                />
                <font-awesome-icon
                  v-else-if="childItems.children && !childItems.status"
                  class="cursor-pointer text-gray-6 side-menu-icon-open sub-child-icon-color"
                  :icon="['fal', 'plus-square']"
                />
              </p>
            </template>
            <div
              v-for="(subChild, subChildIndex) in childItems.children"
              v-show="childItems.status"
              :key="subChildIndex"
              class="menu-item-sub-child"
            >
              <router-link
                :to="{ name: subChild.route }"
                :class="{ 'active-menu': isInAliases(subChild.aliases as string[]) }"
                @click="closeOtherMenu(index, childIndex, subChildIndex)"
              >
                {{ subChild.name }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </ScrollPanel>
  </div>
  <div v-if="showSideMenu" class="blur-page"></div>
  <div class="layout-staff-top-nav-container col" :class="{ cutWidth: showSideMenu }">
    <div class="layout-staff-top-nav-search align-content-center">
      <div class="grid h-full mobile-top-nav justify-content-between">
        <div class="col-4 md:col-4 lg:col-6 justify-content-start align-items-center">
          <font-awesome-icon
            class="menu-user-icon text-2xl mt-3 text-gray-6"
            :icon="['far', 'magnifying-glass']"
            @click.stop="openGlobalSearch"
          />
        </div>
        <div class="col-4 md:col-4 flex justify-content-center">
          <img src="/staff/Logo-Dark.svg" alt="Logo" class="staff-logo-mobile mt-0 grid justify-content-center" />
        </div>
        <div class="col-4 md:col-4 lg:col-6 flex align-items-center justify-content-end">
          <div
            v-show="!showSideMenu"
            class="mobile-toggle-icon flex align-items-center"
            @click.prevent="toggleSideMenu"
          >
            <font-awesome-icon
              class="menu-user-icon mt-3 text-2xl"
              :class="showSideMenu ? 'text-white' : 'text-gray-6'"
              :icon="[showSideMenu ? 'fas' : 'far', showSideMenu ? 'xmark-circle' : 'bars']"
            />
          </div>
        </div>
      </div>
      <GlobalSearch />
    </div>
    <MainContent :is-staff-portal="true" />
  </div>
</template>

<script setup lang="ts">
import { sideMenuItem, sideMenuChildItem } from '../../types/Staff-Dashboard/SideMenu';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import MainContent from '../MainContent.vue';
import { logout } from '../../utilities/logout';
import userAvatarImage from '../../assets/Staff-Dashboard/user-avatar.png';
import GlobalSearch from '../../components/Multipurpose/GlobalSearch.vue';
import useEmitter from '../../composables/useEmitter';
import OverlayPanel from 'primevue/overlaypanel';
import { LocalStorageUserType } from '../../types/Global';
import ImagePhoto from '../../components/Multipurpose/ImagePhoto.vue';
import { isContractor } from '../../utilities/globalHelpers';
import { contractorsMenu, MenuItems } from '../../utilities/menuList';

const emitter = useEmitter();
const confirm = useConfirm();
const router = useRouter();
const currentRoute = ref(router.currentRoute.value.name);
const userMenu = ref<InstanceType<typeof OverlayPanel>>();
const showSideMenu = ref(true);
const pageSize = ref(0);
const userName = ref<string>('Account');
const userAvatar = ref<string>('');
const userDepartment = ref<string>('');
const sideMenuItems = isContractor() ? contractorsMenu : MenuItems;
const openGlobalSearch = () => {
  emitter!.emit('open-global-search');
};

const closeOtherMenu = (index: number, childIndex?: number, subChildIndex?: number) => {
  sideMenuItems.value.forEach((item, itemIndex) => {
    if (itemIndex !== index) {
      item.status = false;
    }
    if (childIndex) {
      item.children.forEach((childItem, childItemIndex) => {
        if (childItemIndex !== childIndex) {
          childItem.status = false;
        }
        if (subChildIndex && childItem.children) {
          childItem.children.forEach((subChildItem, subChildItemIndex) => {
            if (subChildItemIndex !== subChildIndex) {
              subChildItem.status = false;
            }
          });
        }
      });
    }
  });
};
function toggleMenuChildren(index: number) {
  if (!sideMenuItems.value[index].children && sideMenuItems.value[index].route) {
    void router.push({ name: sideMenuItems.value[index].route as string });
    closeSideMenuOnRouteChange();
  } else {
    sideMenuItems.value[index].status = !sideMenuItems.value[index].status;
  }
}
function toggleMenuSubChildren(index: number, childIndex: number) {
  if (!sideMenuItems.value[index].children?.[childIndex].children) {
    if (sideMenuItems.value[index].children?.[childIndex].route) {
      void router.push({ name: sideMenuItems.value[index].children?.[childIndex].route });
      closeSideMenuOnRouteChange();
    } else {
      sideMenuItems.value[index].children[childIndex].status = !sideMenuItems.value[index].children[childIndex].status;
    }
  } else {
    sideMenuItems.value[index].children[childIndex].status = !sideMenuItems.value[index].children?.[childIndex].status;
  }
}

const closeSideMenuOnRouteChange = () => {
  if (pageSize.value <= 769) {
    showSideMenu.value = false;
  }
};
const toggleUserMenu = (event: Event) => {
  userMenu.value?.toggle(event);
};
const changePassword = (event: Event) => {
  userMenu.value?.toggle(event);
  window.open('https://passwordreset.microsoftonline.com', '_blank');
};

const staffProfile = (event: Event) => {
  userMenu.value?.toggle(event);
  void router.push({ name: 'StaffProfile' });
};
const logoutUser = (event: Event) => {
  userMenu.value?.toggle(event);
  confirm.require({
    message: 'Are you sure you want to logout?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    acceptLabel: 'Yes Logout',
    rejectLabel: 'Cancel',
    rejectClass: 'p-button-secondary',
    accept: () => {
      logout(false).catch(() => {});
    }
  });
};
const toggleSideMenu = () => {
  showSideMenu.value = !showSideMenu.value;
};
const isInAliases = (aliases: Array<string>) => {
  let Status = false;
  aliases.forEach((item) => {
    Status = item.toString() === currentRoute.value?.toString();
  });
  return Status;
};

watch(
  () => router.currentRoute.value.name,
  (newData) => {
    currentRoute.value = newData;
  }
);
const setActiveRoute = () => {
  sideMenuItems.value.forEach((item: sideMenuItem) => {
    if (item.route === currentRoute.value) {
      item.status = true;
    } else {
      item.children.forEach((childItem: sideMenuChildItem) => {
        if (childItem.route === currentRoute.value) {
          childItem.status = true;
          item.status = true;
        } else {
          childItem.children?.forEach((subChildItem: sideMenuChildItem) => {
            if (subChildItem.route === currentRoute.value) {
              subChildItem.status = true;
              childItem.status = true;
              item.status = true;
            }
          });
        }
      });
    }
  });
};

onBeforeMount(() => {
  pageSize.value = window.innerWidth;
  closeSideMenuOnRouteChange();
  setActiveRoute();
  const getUser = JSON.parse(localStorage.getItem('user') as string) as LocalStorageUserType;
  if (getUser && getUser.fullName) userName.value = getUser.fullName;
  if (getUser && getUser.avatar) userAvatar.value = getUser.avatar;
  if (getUser && getUser.department) userDepartment.value = getUser.department;
  else userAvatar.value = userAvatarImage;
});
</script>

<style scoped lang="scss">
.layout-staff-side-menu {
  background: #112142;
  box-shadow: 0 1px 2px 1px rgba(167, 172, 211, 0.1);
  height: 100vh;
  position: fixed;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 300px;
  top: 0;
  z-index: 1000;
  left: -300px;
}

.layout-staff-side-menu.isOpen {
  transform: translateX(300px);
}

.side-menu-toggle-icon {
  position: absolute;
  right: -20px;
  top: 10px;
  cursor: pointer;
  width: 40px;
  border: 2px solid #35425e;
  border-radius: 50%;
  color: #fff;
  background-color: #112142;
  height: 40px;
  text-align: center;
}

.side-menu-logo-section {
  background: #112142;
  padding: 20px;
}

.menu-item {
  color: #8890a0;
  padding: 5px 15px;
  letter-spacing: 0.1em;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
.menu-item-children:last-child {
  margin-bottom: -15px;
}
.menu-item-children {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0 20px;
  a {
    color: #8890a0;
    text-decoration: none;
    display: list-item;
    margin: 15px 0;
    height: 30px;
    svg {
      color: rgba(255, 255, 255, 0.5);
      vertical-align: middle;
      font-size: 18px;
      min-width: 26px;
    }
  }
}

.menu-item-name:hover a {
  color: #b8bcc6;
}
.menu-item-name {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  a {
    text-decoration: none;
    color: #8890a0;
  }
  p {
    display: inline-block;
  }

  svg {
    position: absolute;
    right: 0;
    font-size: 20px;
  }
  .side-menu-icon-open {
    color: #666666;
  }
}

.menu-item-child:hover {
  color: #f4f6f9 !important;
}
.menu-item-sub-child:hover a {
  color: #f4f6f9 !important;
  border-left: 1px solid #35425e;
}
.menu-item-name:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: #b8bcc6;
  border-radius: 8px;
}

:deep(.user-avatar) {
  display: inline-block;
  margin-right: 5px;
  width: 38px;
  height: 38px;
  border-radius: 100%;
}
.menu-user-section {
  .menu-user-labels {
    background: #112142;
    border: 1px solid #35425e;
    box-sizing: border-box;
    border-radius: 8px;
    color: white;
    margin: 25px 10px 15px;
    padding: 13px 13px;
    align-items: center;
    display: flex;
    position: relative;

    .user-fullName {
      display: inline-block;
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: #f4f6f9;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 165px;
    }
    .user-department {
      display: inline-block;
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #8890a0;
      min-width: 200px;
    }

    .menu-user-icon {
      display: inline-block;
      float: right;
      color: #8890a0;
      position: absolute;
      right: 13px;
      font-size: 20px !important;
    }
  }
}

.active-menu {
  border-radius: 5px;
  color: #f4b73f !important;
  svg {
    color: white !important;
  }
}
.menu-item-parent {
  padding: 13px 0 13px 48px;
  align-self: center;
  align-items: center;
  display: grid;
  :hover {
    color: #b8bcc6 !important;
  }
}
.menu-item-name .router-link-exact-active {
  background: #f4b73f20;
  border-radius: 8px;
  color: #f4b73f !important;
  width: 100%;
  svg {
    color: #f4b73f !important;
  }
}
.userMenuSection {
  p {
    display: block;
    color: #303030;
    cursor: pointer;
    line-height: 1.8rem;
    margin: 0;
    padding: 5px 20px 5px 5px;

    svg {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  p:hover {
    background-color: #f3f3f3;
  }
}

.layout-staff-top-nav-container {
  padding-bottom: 4px;
  width: calc(100% - 300px);
}

.layout-staff-top-nav-search {
  height: 65px;
  background: white;
  padding: 0;
}

.layout-staff-top-nav-search {
  margin-bottom: 12px;
}

.mobile-toggle-icon {
  font-size: 21px;
  cursor: pointer;
  vertical-align: middle;
  z-index: 10;
  img {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 967px) {
  .layout-staff-top-nav-search {
    padding: 16px;
  }
  .layout-staff-side-menu.isClose .side-menu-toggle-icon {
    display: none;
  }
  .layout-staff-top-nav-search {
    height: 65px;
    background: #112142 !important;
  }
  .top-nav-search-icon {
    visibility: hidden;
  }
  .staff-logo-mobile {
    width: 70px;
    margin-top: 0px !important;
  }
  .side-menu-toggle-icon {
    visibility: hidden;
  }
  .menu-user-icon {
    margin-top: 0 !important;
  }
  .menu-user-labels {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 967px) {
  .blur-page {
    display: none;
  }
  .mobile-top-nav {
    display: none;
  }
  .staff-logo-mobile {
    visibility: hidden;
  }
  .mobile-toggle-icon {
    visibility: hidden;
  }
  .staff-logo-mobile {
    visibility: hidden;
  }
  .layout-staff-side-menu.isClose .side-menu-toggle-icon {
    right: -18px;
    padding-left: 16px;

    :before {
      z-index: -1;
      content: '';
      position: absolute;
      left: 14px;
      width: 2px;
      top: -20px;
      height: 120%;
      background: #cfcfcf;
    }

    img {
      transform: rotate(180deg);
    }
  }

  .layout-staff-top-nav-container.cutWidth {
    transition-delay: 200ms;
    transition-property: padding-left;
    padding-left: 300px;
    width: 100%;
  }
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: fade-in-left 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-out-left {
  -webkit-animation: fade-out-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
}

@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
}

.col-fixed {
  width: 300px;
}

.items-holder {
  max-height: calc(100vh - 240px);
  height: calc(100vh + 290px);
}

:deep(.p-scrollpanel) {
  &.items-holder {
    .p-scrollpanel-bar {
      width: 0;
    }
  }
}

.staff-logo {
  width: 120px;
}

.menu-item-child {
  display: list-item;
  color: #8890a0;
  height: 30px;

  svg {
    position: absolute;
    right: 40px;
    font-size: 20px;
    margin-top: 3px;
  }
}

.menu-item-sub-child {
  a {
    display: block;
    border-left: 1px solid #35425e;
    padding: 5px 11px;
    margin: 0 0 0 -11px;
    height: 40px;
    line-height: 30px;
  }
}

.menu-item-sub-child .router-link-exact-active {
  border-left: 2px solid #f4b73f !important;
}

.menu-item-children .router-link-exact-active {
  color: #f4b73f !important;
}

.sub-child-icon-color {
  color: #8890a0;
}

.menu-user-icon {
  margin-top: 10px;
}

.divider {
  border-top: 1px solid #35425e;
  width: 270px;
  margin: auto;
}

.menu-item-sub-child:last-child {
  margin-bottom: 20px;
}
.menu-item-count {
  position: absolute;
  right: 0;
  height: 19px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #112142;
  background-color: #f4b73f !important;
}
.side-menu-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  vertical-align: middle;
  display: grid;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  left: 12px;
  svg {
    height: auto;
    max-height: 21px;
    max-width: 21px;
  }
}
.blur-page {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 9;
}
.text-gray-6 {
  color: #8890a0 !important;
}
.user-profile-icon {
  font-size: 14px;
}

.userMenuSection i {
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  color: #757575;
}

.userMenuSection span {
  color: var(--staff-ref-gray-07, #757575);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.userMenuSection {
  display: flex !important;
  flex-direction: column !important;
  gap: 12px !important;
}

.userMenuSection p {
  padding: 0 !important;
}

.p-overlaypanel-flippe {
  position: fixed;
}
.menu-user-section .menu-user-labels:hover {
  border: 1px solid #f4b73f;
  background: rgba(244, 183, 63, 0.1);
}
</style>
