import { App } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAcorn,
  faPlusSquare,
  faMinusSquare,
  faTrash,
  faCheck,
  faPencil,
  faPlus,
  faCodeBranch,
  faClock,
  faGear,
  faCheckCircle,
  faSign,
  faTruck,
  faExchange,
  faWrench,
  faFileArchive,
  faClone,
  faFileImage,
  faFolderUpload,
  faFileCheck,
  faMapMarkedAlt,
  faSave,
  faArchive,
  faInfo,
  faAddressBook,
  faInfoSquare,
  faChevronDown,
  faChevronUp,
  faMemo as faMemoSolid,
  faFolder,
  faXmarkCircle,
  faFileMagnifyingGlass as faFileMagnifyGlass,
  faBookmark,
  faArrowDownLeft
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFolderMagnifyingGlass,
  faListCheck,
  faLayerGroup,
  faEnvelopeOpenDollar,
  faMoneyCheckEditAlt,
  faBoxUsd,
  faBallotCheck,
  faFileContract,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faLongArrowLeft,
  faFileCertificate,
  faDollyFlatbedAlt,
  faTag,
  faCogs,
  faUserCog,
  faBuilding,
  faCubes,
  faFileExcel,
  faFileChartLine,
  faRouter,
  faUserChart,
  faHistory,
  faRulerTriangle,
  faLayerPlus,
  faChartNetwork,
  faArrowLeft,
  faBrowsers,
  faHouseBlank,
  faMapMarkerAlt,
  faBoxesStacked,
  faMagnifyingGlassChart,
  faMagnifyingGlass,
  faChalkboardUser,
  faClipboardListCheck,
  faCalculator,
  faCalculatorSimple,
  faBorderAll,
  faCalendar,
  faHand,
  faComment,
  faRectangleHistory,
  faGearComplex,
  faPlusSquare as faPlusSquareLight,
  faMinusSquare as faMinusSquareLight,
  faFileMagnifyingGlass,
  faFileCircleCheck as faFileCircleCheckLight,
  faBox as faBoxLight,
  faFolders as faFoldersLight,
  faMemo as faMemoLight,
  faTruckFast,
  faUsers as FaUsersLight
} from '@fortawesome/pro-light-svg-icons';
import {
  faSignOutAlt,
  faKey,
  faEye,
  faChevronLeft,
  faChevronRight,
  faBars,
  faXmark,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faFolder as faFolderRegular,
  faFolderOpen,
  faUser,
  faHomeBlank,
  faUsers,
  faToolbox,
  faBox,
  faFolders,
  faFileCircleCheck,
  faFileLines,
  faBarsProgress,
  faContainerStorage,
  faReceipt,
  faMemo,
  faFileExport,
  faArrowUpFromBracket,
  faSquareInfo,
  faFileCheck as faFileCheckRegular,
  faGear as faGearRegular,
  faEye as faEyeRegular,
  faTruckFast as faTruckFastRegular,
  faArrowTurnDownLeft,
  faFileZip,
  faFileExcel as faFileExcelRegular,
  faFileCode,
  faFileWord,
  faFilePdf,
  faBell,
  faBellSlash,
  faCloudArrowDown,
  faFileImage as faFileImageRegular,
  faCircleXmark,
  faGrid2,
  faCalendar as faCalendarRegular,
  faMagnifyingGlassChart as faMagnifyingGlassChartRegular,
  faMapMarkerAlt as faMapMarkerAltRegular,
  faLoader,
  faPhoneMissed,
  faPhoneXmark,
  faPhoneHangup,
  faPhone,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPowerOff,
  faLocationDot,
  faBoxesStacked as faBoxesStackedRegular,
  faList,
  faBan,
  faLink,
  faLinkSlash,
  faPaperPlaneTop,
  faHourglassClock,
  faMoneyBill,
  faFileInvoiceDollar,
  faMobile,
  faEllipsis,
  faCircleInfo,
  faDownload,
  faArrowUpRightFromSquare
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faFolderMagnifyingGlass,
  faBrowsers,
  faLayerGroup,
  faCheck,
  faEnvelopeOpenDollar,
  faMoneyCheckEditAlt,
  faBoxUsd,
  faBallotCheck,
  faBellSlash,
  faBell,
  faRouter,
  faFileChartLine,
  faCubes,
  faAcorn,
  faPlusSquare,
  faMinusSquare,
  faEye,
  faEyeRegular,
  faFileContract,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faLongArrowLeft,
  faSignOutAlt,
  faKey,
  faUser,
  faTrash,
  faPencil,
  faPlus,
  faCodeBranch,
  faClock,
  faGear,
  faFileCertificate,
  faCheckCircle,
  faSign,
  faDollyFlatbedAlt,
  faTag,
  faTruck,
  faTruckFast,
  faExchange,
  faWrench,
  faFileArchive,
  faClone,
  faFileImage,
  faCogs,
  faUserCog,
  faFolderUpload,
  faFileCheck,
  faMapMarkedAlt,
  faSave,
  faArchive,
  faInfo,
  faBuilding,
  faAddressBook,
  faInfoSquare,
  faFileExcel,
  faUserChart,
  faHistory,
  faRulerTriangle,
  faReceipt,
  faLayerPlus,
  faChartNetwork,
  faArrowLeft,
  faListCheck,
  faChevronLeft,
  faChevronRight,
  faHouseBlank,
  faMapMarkerAlt,
  faBoxesStacked,
  faFileCircleCheck,
  faBox,
  faChevronDown,
  faChevronUp,
  faMagnifyingGlassChart,
  faFolders,
  faMemo,
  faMagnifyingGlass,
  faBars,
  faXmark,
  faChalkboardUser,
  faClipboardListCheck,
  faCalculator,
  faCalculatorSimple,
  faBorderAll,
  faCalendar,
  faHand,
  faFileLines,
  faFolder,
  faComment,
  faRectangleHistory,
  faGearComplex,
  faFolderRegular,
  faFolderOpen,
  faFileMagnifyingGlass,
  faFileMagnifyGlass,
  faUsers,
  faToolbox,
  faBox,
  faFileCircleCheck,
  faContainerStorage,
  faBarsProgress,
  faGearRegular,
  faMinusSquareLight,
  faMagnifyingGlassRegular,
  faPlusSquareLight,
  faHomeBlank,
  faXmarkCircle,
  faFileCircleCheckLight,
  faBoxLight,
  faFoldersLight,
  faMemoLight,
  faMemoSolid,
  faTruckFastRegular,
  faArrowTurnDownLeft,
  faFileExport,
  faArrowUpFromBracket,
  faFileCheckRegular,
  faSquareInfo,
  faFileZip,
  faFileExcelRegular,
  faFileCode,
  faFileWord,
  faFilePdf,
  faCloudArrowDown,
  faFileImageRegular,
  faCircleXmark,
  faGrid2,
  faCalendarRegular,
  faBoxesStackedRegular,
  faMapMarkerAltRegular,
  faLoader,
  faPhoneMissed,
  faPhoneXmark,
  faPhoneHangup,
  faPhone,
  FaUsersLight,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPowerOff,
  faMagnifyingGlassChartRegular,
  faLocationDot,
  faList,
  faBan,
  faLink,
  faLinkSlash,
  faPaperPlaneTop,
  faHourglassClock,
  faMoneyBill,
  faFileInvoiceDollar,
  faBookmark,
  faMobile,
  faArrowDownLeft,
  faEllipsis,
  faDownload,
  faCircleInfo,
  faArrowUpRightFromSquare
);

export const registerFontawesome = (app: App) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon);
};
