<template>
  <div class="cp-content" :class="[isDark ? 'is-dark' : 'is-light', isLargeContent ? 'cp-content-large' : '']">
    <GlobalSearch :is-customer-portal="true" />
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from 'vue';
import useEmitter from '../../composables/useEmitter';
import GlobalSearch from '../../components/Multipurpose/GlobalSearch.vue';

const emitter = useEmitter();
const isDark = ref(getCurrentInstance()?.appContext.config.globalProperties.$isDark);
const isLargeContent = ref(false);
emitter!.on<'toggle-large-content'>('toggle-large-content', (status: boolean) => {
  isLargeContent.value = status;
});
</script>

<style scoped lang="scss">
.cp-content {
  position: fixed;
  left: 287px;
  top: 0;
  min-height: 100vh;
  width: calc(100% - 287px);
  height: 200px;
  overflow: auto;
  padding-bottom: 115px;
}

.cp-content-large {
  width: calc(100% - 110px);
  left: 110px;
}

.is-light {
  background: #fafafa;
}
.is-dark {
  background: #121212;
}
@media screen and (max-width: 967px) {
  .cp-content {
    top: 64px;
    left: 0;
    width: 100%;
  }
}
</style>
