import BaseLayout from '../layouts/Customer-Dashboard/BaseLayout.vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { logout } from '../utilities/logout';
import axios from 'axios';
import { UserType } from '../types/Global';

const loadView = (view: string) => {
  return () => import(`../views/Customer-Dashboard/${view}.vue`);
};
export const CustomerDashboardRouter = [
  { path: '/register', name: 'Register', component: loadView('RegisterCustomer'), meta: { title: 'Register' } },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: loadView('ForgetPassword'),
    meta: { title: 'Forget Password' }
  },
  {
    redirect: { name: 'DashboardCustomer' },
    path: '/customer',
    component: BaseLayout,
    children: [
      {
        name: 'DashboardCustomer',
        path: 'dashboard',
        component: loadView('Dashboard'),
        meta: { title: 'Dashboard' }
      },
      {
        name: 'Profile',
        path: 'profile/view',
        component: loadView('Profile'),
        meta: { title: 'Profile' }
      },
      {
        name: 'ProfileSetting',
        path: 'profile/setting',
        component: loadView('ProfileSetting'),
        meta: { title: 'Profile Setting' }
      },
      {
        name: 'ProfilePassword',
        path: 'profile/password',
        component: loadView('ProfilePassword'),
        meta: { title: 'Profile Change Password' }
      },
      {
        name: 'InventoryList_CP',
        path: 'inventory-list',
        component: loadView('InventoryList'),
        meta: { title: 'Inventory List' }
      },
      {
        name: 'LocationList_CP',
        path: 'location-list',
        component: loadView('LocationList'),
        meta: { title: 'Location List' }
      },
      {
        name: 'LocationDetail_CP',
        path: 'location-detail/:id',
        component: loadView('LocationDetail'),
        meta: { title: 'Location Detail' }
      },
      {
        name: 'QualificationList_CP',
        path: 'qualification-list',
        component: loadView('QualificationList'),
        meta: { title: 'Qualification List' }
      },
      {
        name: 'QualificationDetail_CP',
        path: 'qualifications/:id',
        component: loadView('QualificationDetail'),
        meta: { title: 'Qualification Details' }
      },
      {
        name: 'CancellationList_CP',
        path: 'cancellation-list',
        component: loadView('CancellationList'),
        meta: { title: 'Cancellation List' }
      },
      {
        name: 'CancellationDetail_CP',
        path: 'cancellation-detail/:id',
        component: loadView('CancellationDetail'),
        meta: { title: 'Cancellation Detail' }
      },
      {
        name: 'OrderList_CP',
        path: 'order-list',
        component: loadView('OrderList'),
        meta: { title: 'Order List' }
      },
      {
        name: 'OrderDetail_CP',
        path: 'order-detail/:id',
        component: loadView('OrderDetail'),
        meta: { title: 'Order Detail' }
      },
      {
        name: 'ProcurementList_CP',
        path: 'misc-service-list',
        component: loadView('ProcurementList'),
        meta: { title: 'MISC Services' }
      },
      {
        name: 'ProcurementDetail_CP',
        path: 'misc-service-detail/:id',
        component: loadView('ProcurementDetail'),
        meta: { title: 'MISC Service Detail' }
      },
      {
        name: 'Calendar_CP',
        path: 'calendar',
        component: loadView('OpenVisit'),
        meta: { title: 'Resource Calendar' }
      },
      {
        name: 'VisitList_CP',
        path: 'visits-list',
        component: loadView('VisitList'),
        meta: { title: 'Visit' }
      },
      {
        name: 'VisitDetail_CP',
        path: 'visits-detail/:id',
        component: loadView('VisitDetail'),
        meta: { title: 'Visit Details' }
      },
      {
        name: 'DataUsageHistory_CP',
        path: 'data-usage-history',
        component: loadView('DataUsageHistory'),
        meta: { title: 'Data Usage History' }
      },
      {
        name: 'DataUsageHistoryDetailsCP',
        path: 'data-usage-history-details/:id',
        component: loadView('DataUsageHistoryDetail'),
        meta: { title: 'Data Usage History Details' }
      },
      {
        name: 'DataUsageRequestsListCustomer',
        path: 'data-usage-requests-list',
        component: loadView('DataUsageRequestsList'),
        meta: { title: 'Data Usage Requests List' }
      },
      {
        name: 'DataUsageRequestsAddEditCustomer_CP',
        path: 'data-usage-requests/:id',
        component: loadView('DataUsageRequestsAddEdit'),
        meta: { title: 'Data Usage Request' }
      },
      {
        name: 'Tem_CP',
        path: 'tem',
        component: loadView('Tem'),
        meta: { title: 'TEM' }
      },
      {
        name: 'DataUsage_CP',
        path: 'data-usage',
        component: loadView('DataUsage'),
        meta: { title: 'Data Usage' }
      },
      {
        name: 'DataUsageDetails_CP',
        path: 'data-usage-details/:id',
        component: loadView('DataUsageDetails'),
        meta: { title: 'Data Usage Details' }
      },
      {
        name: 'UserManagement_CP',
        path: 'user-management',
        component: loadView('UserManagement'),
        meta: { title: 'User Management' }
      },
      {
        name: 'TokenManagement_CP',
        path: 'token-management',
        component: loadView('TokenManagement'),
        meta: { title: 'Token Management' }
      },
      {
        name: 'TokenList_CP',
        path: 'token-list/:id',
        component: loadView('TokenList'),
        meta: { title: 'Token Management' }
      },
      {
        name: 'TokenDoc_CP',
        path: 'token/documentation',
        component: loadView('TokenDoc'),
        meta: { title: 'Documentation' }
      },
      {
        name: 'TicketList_CP',
        path: 'support-ticket',
        component: loadView('TicketList'),
        meta: { title: 'Support Ticket' }
      },
      {
        name: 'CreateTicket_CP',
        path: 'support-ticket/create',
        component: loadView('AddEditTicket'),
        meta: { title: 'Create Ticket' }
      },
      {
        name: 'TicketDetail_CP',
        path: 'support-ticket/:id',
        component: loadView('TicketDetail'),
        meta: { title: 'Support Ticket Detail' }
      },
      {
        name: 'RequestVisit_CP',
        path: 'request-visit',
        component: loadView('RequestVisit'),
        meta: { title: 'request visit' }
      },
      {
        name: 'Monitoring_CP',
        path: 'monitoring',
        component: loadView('Monitoring'),
        meta: { title: 'Monitoring' }
      },
      {
        name: 'MonitoringDeviceDetail_CP',
        path: 'monitoring/:id',
        component: loadView('MonitoringDeviceDetail'),
        meta: { title: 'Monitoring Device Detail' }
      },
      {
        name: 'Customer-ReportList',
        path: 'reports',
        component: loadView('Reports'),
        meta: { title: 'Report List' }
      },
      {
        name: 'Customer-ReportScheduleList',
        path: 'reports/schedules/:reportId',
        component: loadView('ReportSchedules'),
        meta: { title: 'ReportSchedule List' }
      },
      {
        name: 'Customer-ReportRunResult',
        path: 'reports/run/:reportId',
        component: loadView('ReportRunResult'),
        meta: { title: 'Report Run Result' }
      }
    ]
  }
];

const setUser = async (toPath: string) => {
  if (toPath.startsWith('/customer')) {
    await axios
      .get<UserType>('/panel/customer/profile')
      .then((data) => {
        if (data.status !== 200) throw data;
        const userObjectToSave = {
          name: data.data.name || '',
          email: data.data.email || '',
          company: data.data.company || '',
          avatar: data.data.avatar || '',
          is_admin: data.data.is_admin ?? ''
        };
        localStorage.setItem('user', JSON.stringify(userObjectToSave));
        if (data.data.is_dark) {
          localStorage.setItem('isDark', 'true');
        } else {
          localStorage.removeItem('isDark');
        }
      })
      .catch(() => {
        logout().catch(() => {});
      });
  }
};

export const beforeEachCustomerDashboard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const toRoute: string = to.name ? to.name.toString() : '';
  const toPath: string = to.fullPath ? to.fullPath.toString().toLowerCase() : '';
  await setUser(toPath);
  if (toRoute === 'Login' && localStorage.getItem('token')) next({ name: 'DashboardCustomer' });
  else if (toPath.startsWith('/customer') && !localStorage.getItem('token')) {
    await logout(false);
  }
  next();
};
